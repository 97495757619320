<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ProductService from '@/services/product'

export default {
  page: {
    title: "Atualizar Produtos",
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Atualizar Produtos",
      loading: false,
      fileToUpload: {},
      logs: [],
      options: {
        updatePrice: true,
        updateStock: true,
      },
    }
  },
  methods: {
    loadFileData(e) {
      this.fileToUpload = e.target.files[0];
    },
    uploadData() {
      this.logs = [];
      let loader = this.$loading.show();

      ProductService.uploadProductFile(this.fileToUpload, this.options).then(res => {
        loader.hide();
        this.logs = res.data.logs;

        this.$toast.open('Arquivo processado com sucesso');
      }).catch((err) => {
        loader.hide();

        if (err.response.data.code && err.response.data.code === 'E9999') {
          this.$toast.error(err.response.data.msg);
        } else {
          this.$toast.error('Ocorreu um erro ao enviar o cadastro');
        }
      })
    }
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Atualização por Arquivo</h4>
            <p class="card-title-desc">
              Selecione o arquivo .CSV com os produtos para importação seguindo o padrão de colunas (código de barras, SKU, estoque e preço).
            </p>
            <div class="custom-file">
              <input id="customFile" type="file" class="custom-file-input" @change="loadFileData" accept=".csv" />
              <label class="custom-file-label" for="customFile">{{fileToUpload.name}}</label>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="update_price" v-model="options.updatePrice" />
                    <label class="custom-control-label" for="update_price">Atualizar preço</label>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="update_stock" v-model="options.updateStock" />
                    <label class="custom-control-label" for="update_stock">Atualizar estoque</label>
                  </div>
                </div>
              </div>
            </div>

            <button class="btn btn-primary mt-3" type="button" @click="uploadData" :disabled="!fileToUpload.name || (!options.updatePrice && !options.updateStock)">
              Iniciar Importação
            </button> 
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="logs && logs.length">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Logs</h4>
            <p class="card-title-desc">
              Confira o resultado do processamento da importação
            </p>

            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>SKU</th>
                    <th>Estoque</th>
                    <th>Preço</th>
                    <th>Mensagem</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="log in logs" :key="log">
                    <th scope="row">
                      <i class="bx bx-check-circle mr-2 text-success" v-if="log.updated"></i>
                      <i class="bx bx-error-circle mr-2 text-warning" v-if="!log.updated"></i>
                    </th>
                    <td>{{log.sku}}</td>
                    <td>{{log.stock}}</td>
                    <td>{{log.price}}</td>
                    <td>{{log.message}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>  
  </Layout>
</template>